// Logos, icons, and image constants
import LOGO_FULL from 'public/logos/horizon/blue/horizon_logo_blue_full.png';
import LOGO_ICON from 'public/logos/horizon/blue/horizon_icon_blue.png';
import SKYSPECS_LOGO from 'public/logos/skyspecs/skyspecs-black.png';
import TURBINE_ICON from 'public/icons/wind-turbine.svg';
import TURBINE_OUTLINED_ICON from 'public/icons/wind-turbine-outlined.svg';
import MARKER_BLUE from 'public/icons/marker-blue.png';
import CIRCLE_MINOR_ICON from 'public/icons/circle-minor.svg';
import CIRCLE_GOOD_ICON from 'public/icons/circle-good.svg';
import SQUARE_POOR_ICON from 'public/icons/square-poor.svg';
import TRIANGLE_MAJOR_ICON from 'public/icons/triangle-major.svg';
import TRIANGLE_CRITICAL_ICON from 'public/icons/triangle-critical.svg';

export {
  LOGO_FULL,
  LOGO_ICON,
  SKYSPECS_LOGO,
  TURBINE_ICON,
  TURBINE_OUTLINED_ICON,
  MARKER_BLUE,
  CIRCLE_MINOR_ICON,
  CIRCLE_GOOD_ICON,
  SQUARE_POOR_ICON,
  TRIANGLE_MAJOR_ICON,
  TRIANGLE_CRITICAL_ICON,
};

// App constants
export const TABLE_SCROLL_WIDTH = 1280;
export const TABLE_SCROLL_WIDTH_SMALL = 640;

// format constants
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';

// Log field types constants
export const STRING = 'STRING';
export const NUMBER = 'NUMBER';
export const MULTI = 'MULTI';
export const TIME_RANGE = 'TIME_RANGE';
export const CALCULATED = 'CALCULATED';
export const DATE = 'DATE';

// Work container type constants
export const SELECT = 'SELECT';
export const TEXT = 'TEXT';

// Tasks
export const TASK_TYPE_REPAIR = 'REPAIR';
export const REPAIR_TASK = 'REPAIR_TASK';
export const TASK_TYPE_OTHER = 'OTHER';
export const OTHER_TASK = 'OTHER_TASK';
export const TASK_TYPE_INSPECT = 'INSPECT';
export const INSPECT_TASK = 'INSPECT_TASK';
export const TASK_TYPE_INTERNAL_BLADE_INSPECTION = 'INTERNAL_BLADE_INSPECTION';
export const INTERNAL_BLADE_INSPECTION_TASK = 'INTERNAL_BLADE_INSPECTION_TASK';

// Work Orders / Campaigns
export const CAMPAIGN = 'CAMPAIGN';
export const WORK_CAMPAIGN = 'WORK_CAMPAIGN';
export const WORK_ORDER = 'WORK_ORDER';

// Assets
export const ASSET_TYPE_LOCATION = 'Location';
export const ASSET_TYPE_TURBINE = 'Turbine';
export const ASSET_TYPE_BLADE = 'Blade';

// stats (field definitions)
export const SUM = 'sum';
export const MEAN = 'mean';

// inspection vendor
export const CORNIS = 'cornis';
export const SKYSPECS = 'skyspecs';
export const THIRD_PARTY = 'third party';

export const TASK_STATUSES = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
};

export const TASK_STATUS_LABELS = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  COMPLETE: 'Complete',
};

export const LOG_FIELD_TYPE_OPTIONS = {
  [STRING]: 'Text',
  [MULTI]: 'Dropdown',
  [NUMBER]: 'Number',
  [TIME_RANGE]: 'Time Range',
  [CALCULATED]: 'Calculated',
  [DATE]: 'Date',
};

export const WORK_CONTAINER_FIELD_TYPE_OPTIONS = {
  [TEXT]: 'Text',
  [NUMBER]: 'Number',
  [TIME_RANGE]: 'Time Range',
  [DATE]: 'Date',
  [SELECT]: 'Dropdown',
};

export const WORK_LOG_TYPE_OPTIONS = {
  [WORK_CAMPAIGN]: 'Campaign',
  [WORK_ORDER]: 'Work Order',
  [TASK_TYPE_OTHER]: 'Task',
};

export const WORK_CONTAINER_TYPE_OPTIONS = {
  [CAMPAIGN]: 'Campaign',
  [WORK_ORDER]: 'Work Order',
  [REPAIR_TASK]: 'Repair Task',
  [INSPECT_TASK]: 'Inspect Task',
  [INTERNAL_BLADE_INSPECTION_TASK]: 'Internal Blade Inspection Task',
  [OTHER_TASK]: 'Other Task',
};

export const WORK_CONTAINER = 'WORK_CONTAINER';

export const SEVERITIES = [1, 2, 3, 4, 5];

export const STATS = [SUM, MEAN];

export const INGESTION_CODES = {
  NO_INSPECTION: 'No inspection in progress for turbine',
};

export const TABLE_FILTER_OPERATORS = {
  EQUALS: 'EQUALS',
  NOT_EQUALS: 'NOT_EQUALS',
  CONTAINS: 'CONTAINS',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
  LESS_THAN: 'LESS_THAN',
  LESS_THAN_OR_EQUAL: 'LESS_THAN_OR_EQUAL',
  IS_NULL: 'IS_NULL',
  IS_NOT_NULL: 'IS_NOT_NULL',
  ARRAY_INCLUDES: 'ARRAY_INCLUDES',
};

//regex to validate id for routing
export const uuidRegex =
  '([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})';

export const INSPECTION_UPLOAD_STATUSES = {
  DRAFT: 'DRAFT',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
};
export const BLADE_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE_STORAGE: 'INACTIVE_STORAGE',
  INACTIVE_EOL: 'INACTIVE_EOL',
};

export const BLADE_STATUS_LABELS = {
  ACTIVE: 'Active',
  INACTIVE_STORAGE: 'Storage',
  INACTIVE_EOL: 'Recycled',
};

// Inspection Type
export const BLADE_INTERNAL = 'Blade Internal';
export const AUTONOMOUS_DRONE = 'Autonomous Drone';
export const OTHER = 'Other';

export const READABLE_CHAMBER_OPTIONS = {
  'trailing-edge': {
    label: 'Trailing Edge',
    order: 4,
  },
  trailing_edge: {
    label: 'Trailing Edge',
    order: 4,
  },
  root: {
    label: 'Root',
    order: 0,
  },
  'leading-edge': {
    label: 'Leading Edge',
    order: 1,
  },
  leading_edge: {
    label: 'Leading Edge',
    order: 1,
  },
  'between-web-1': {
    label: 'Between Web 1',
    order: 2,
  },
  between_web_1: {
    label: 'Between Web 1',
    order: 2,
  },
  'between-web-2': {
    label: 'Between Web 2',
    order: 3,
  },
  between_web_2: {
    label: 'Between Web 2',
    order: 3,
  },
};

export const METADATA_UPLOAD_OPTIONS = {
  SKYSPECS_JSON: 'inspection-metadata-json',
  SKYSPECS_CSV: 'inspection-metadata-csv',
  CORNIS: 'inspection-metadata-cornis',
  UNUSED: 'inspection-metadata-ndjson',
  CORNIS_BULK: 'inspection-bulk-cornis',
  CORNIS_BULK_VALIDATE: 'inspection-bulk-cornis-validate',
  CORNIS_BULK_VALIDATED: 'inspection-bulk-cornis-validated',
};

export const CORNIS_BULK_UPLOAD_OPTIONS = {
  CORNIS_BULK: 'inspection-bulk-cornis',
  CORNIS_BULK_VALIDATE: 'inspection-bulk-cornis-validate',
  CORNIS_BULK_VALIDATED: 'inspection-bulk-cornis-validated',
  CORNIS_BULK_GENERATE: 'inspection-bulk-cornis-generate',
};

export const CSV_BULK_UPLOAD_OPTIONS = {
  CSV_BULK: 'inspection-bulk-csv',
  CSV_BULK_VALIDATE: 'inspection-bulk-csv-validate',
  CSV_BULK_VALIDATED: 'inspection-bulk-csv-validated',
  CSV_BULK_GENERATE: 'inspection-bulk-csv-generate',
};

export const JSON_BULK_UPLOAD_OPTIONS = {
  JSON_BULK: 'inspection-bulk-json',
  JSON_BULK_VALIDATE: 'inspection-bulk-json-validate',
  JSON_BULK_VALIDATED: 'inspection-bulk-json-validated',
  JSON_BULK_GENERATE: 'inspection-bulk-json-generate',
};
