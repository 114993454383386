import COLORS from 'utils/color/definitions';

// use the theme where possible to style your components instead of importing the definitions directly into your component

export default {
  backgroundColor: COLORS.BACKGROUND_COLOR,
  black: COLORS.BLACK,
  blue: COLORS.BLUE,
  borderColor: COLORS.BORDER_COLOR,
  descriptionColor: COLORS.DESCRIPTION_COLOR,
  gray: COLORS.GRAY,
  green: COLORS.GREEN,
  horizonGreen: COLORS.HORIZON_GREEN,
  labelColor: COLORS.LABEL_COLOR,
  lightBlue: COLORS.LIGHT_BLUE,
  lightGray: COLORS.LIGHT_GRAY,
  lightRed: COLORS.LIGHT_RED,
  overlayBlack: COLORS.OVERLAY_BLACK,
  red: COLORS.RED,
  secondaryTextColor: COLORS.SECONDARY_TEXT_COLOR,
  textColor: COLORS.TEXT_COLOR,
  white: COLORS.WHITE,
};
