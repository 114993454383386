import React from 'react';
import { Route } from 'react-router-dom';
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { storage } from './localStorage';
import { setHeapUser } from './heap';
import { setBugsnagUser } from './bugsnag';
//import { setLogRocketUser } from './logrocket';

const { DEPLOY_ENV, AUTH0_CLIENT_ID } = process.env;

export const AUTH0_SCOPE = 'openid profile email offline_access read:current_user';

export function AuthenticatedRoute({ component: Component, ...args }) {
  return (
    <Route
      component={withAuthenticationRequired(Component, { clientId: AUTH0_CLIENT_ID })}
      {...args}
    />
  );
}

export const Auth0ProviderWithHistory = ({ children }) => {
  const domain = `login.${DEPLOY_ENV === 'prod' ? '' : `${DEPLOY_ENV}.`}skyspecs.com`;
  const history = useHistory();

  return (
    <Auth0Provider
      domain={domain}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={appState => {
        history.push(appState?.returnTo || window.location.pathname);
      }}
      audience="https://api.int.skyspecs.com/fleet/graphql"
      scope={AUTH0_SCOPE}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

// TODO JJ: hacked to hardcode roles until auth0 groups are figured out
export function hasUserRole(...args) {
  const groups = storage.getItem('ohi:groups') || [];
  return groups.some(role => args.includes(role));
}

export function setThirdPartyUser(idToken) {
  setBugsnagUser(idToken);
  //setLogRocketUser(idToken);
  setHeapUser(idToken);
}

// TODO JJ: this is used in the apollo client to clear cache, see how to do that with auth0
// export const listenTo = (e, cb) =>
//   Hub.listen('auth', ({ payload: { event, ...payload } }) => {
//     if (event === e) {
//       cb(payload);
//     }
//   });
