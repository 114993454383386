import React from 'react';
import styled from 'styled-components';

import { FlexContainer } from 'utils/layouts/containers';

import { SKYSPECS_LOGO } from 'utils/constants';

const Footer = styled(FlexContainer.withComponent('footer'))`
  display: flex;
  flex-direction: column;
  margin: 2rem;

  img {
    box-sizing: content-box;
    width: 13rem;
    padding: 0 2em;
  }
`;

const PrivacyLink = styled.a`
  color: ${({ theme }) => theme.black};
`;

const Line = styled.hr`
  width: 16rem;
  border-top-width: 0;
  border-color: ${({ theme }) => theme.borderColor};
  margin: 1rem 0;
`;

const GlobalFooter = () => (
  <Footer>
    <img alt="skyspecs logo" src={SKYSPECS_LOGO} />
    <Line />
    <PrivacyLink target="_blank" href="https://skyspecs.com/privacy/" rel="noopener noreferrer">
      Privacy Policy
    </PrivacyLink>
  </Footer>
);

export default GlobalFooter;
