// include bugsnag first to catch any load/init issues
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { Auth0ProviderWithHistory } from 'utils/auth';
import { AuthenticatedRoute } from 'utils/auth';
import { ApolloClientProvider } from 'utils/apollo';

import App from './App';
import registerServiceWorker from 'registerServiceWorker';

import { ThemeProvider } from 'styled-components';
import skyspecsTheme from 'utils/color/theme-skyspecs';
import { setTitle } from 'utils/document';

setTitle('Analyst');

ReactDOM.render(
  <ErrorBoundary>
    <Router>
      <Auth0ProviderWithHistory>
        <ApolloClientProvider>
          <ThemeProvider theme={skyspecsTheme}>
            <AuthenticatedRoute path="/" component={App} />
          </ThemeProvider>
        </ApolloClientProvider>
      </Auth0ProviderWithHistory>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
);

registerServiceWorker();
