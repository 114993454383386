import UAParser from 'ua-parser-js';

//helper functions for the document
export function setTitle(app) {
  document.title = `${app} · SkySpecs`;
}

export function isIE() {
  const parser = new UAParser();
  return parser.getBrowser().name === 'IE';
}
