// TODO JJ: You'll also see a util file "storage" that is using localForage.  localforage uses
// promises which does not work for some of our use cases.
const jsonEncodedItems = ['ohi:groups'];

/**
 * Retrieve an item from localStorage
 *
 * @param {string} key The localStorage key name
 * @param {boolean} parse When true, parse the data after retrieving from localStorage
 * @returns mixed
 */
export function getItem(key, parse = false) {
  try {
    const item = localStorage.getItem(key);
    if (item && (parse || jsonEncodedItems.includes(key))) {
      const parsed = JSON.parse(item);
      return parsed;
    }
    return item;
  } catch (e) {
    console.error(`Unable to retrieve item from localStorage: ${key}`, e.message);
    return null;
  }
}

/**
 * Set an item into localStorage
 *
 * @param {string} key The localStorage key name
 * @param {mixed} value The data to add to localStorage at the key provided
 * @param {boolean} stringify When true, stringify the data before adding to localStorage
 */
export function setItem(key, value, stringify = false) {
  try {
    const stringified =
      stringify || (jsonEncodedItems.includes(key) && typeof value !== 'string')
        ? JSON.stringify(value)
        : String(value);
    localStorage.setItem(key, stringified);
  } catch (e) {
    console.error(`Unable to add item to localStorage: ${key}`);
  }
}

/**
 * Remove an item from localStorage
 *
 * @param {string} key The localStorage key name
 */
export function removeItem(key) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error(`Unable to remove item from localStorage: ${key}`);
  }
}

/**
 * Retrieve an item from localStorage, and then delete it from localStorage
 *
 * @param {string} key The localStorage key name
 * @param {boolean} parse When true, parse the data after retrieving from localStorage
 * @returns mixed
 */
export function pluck(key, parse = false) {
  const item = getItem(key, parse);
  removeItem(key);
  return item;
}

/**
 * Remove all the items from localStorage
 */
export function clear() {
  try {
    localStorage.clear();
  } catch (e) {
    console.error('Unable to clear localStorage');
  }
}

const storageManager = ({ storageKey }) => {
  return {
    get: () => (storageKey ? getItem(storageKey, true) : null),
    set: value => (storageKey ? setItem(storageKey, value, true) : {}),
  };
};

const storage = {
  clear,
  getItem,
  pluck,
  removeItem,
  setItem,
};

export { storage, storageManager };
