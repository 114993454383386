import { BugSnag } from 'utils/bugsnag';
import { FlexContainer } from 'utils/layouts/containers';
import React from 'react';
import styled from 'styled-components';

const StyledError = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  p {
    margin: 0;
  }
`;

const Oops = () => (
  <StyledError>
    <h1>Oops!</h1>
    <p>Something went wrong</p>
  </StyledError>
);

export default props => <BugSnag FallbackComponent={Oops} {...props} />;
