import React from 'react';
import { from, ApolloLink, ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import { message } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';

import { bugsnagClient } from './bugsnag';
import { AUTH0_SCOPE } from 'utils/auth';

const { API_HOSTNAME, APP_NAME } = process.env;

export const ApolloClientProvider = props => {
  const { getAccessTokenSilently } = useAuth0();

  const client = () => {
    const errMsg =
      'There was an error processing your request. If this error persists please contact support.';

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        message.error(
          `There were some issues with a request. If this error persists please contact support.`
        );

        graphQLErrors.forEach(error => {
          const message = `[GraphQL error]: ${JSON.stringify(error)}`;
          console.error(message);
          bugsnagClient.notify(message);
        });
      } else if (navigator.onLine && networkError) {
        message.error(errMsg);
        console.error(`[Network error]: ${networkError}`);
        setTimeout(
          () => bugsnagClient.notify(new Error(`[GraphQL Network error]: ${networkError}`)),
          10000
        );
      }
    });

    const retryLink = new RetryLink({
      // @see https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
      attempts: (count, operation) => {
        // it is assumed that "retry" attempts are additional request attempts.
        // e.g. if `retryAttempts` is 0, that means, only run the request once. if
        // `retryAttempts` is 1, only run the request up to 2 times (initial
        // request + 1 retry).
        //
        // the default is 5 attempts (aka, 4 retry attempts)
        const contextualRetryAttempts = operation.getContext().retryAttempts;
        const retryAttempts = contextualRetryAttempts ?? 4;

        return count < retryAttempts + 1;
      },
    });

    const authLink = new ApolloLink(async (operation, forward) => {
      // get the authentication token from local storage if it exists
      const { headers } = operation.getContext();
      const token = await getAccessTokenSilently({
        // the `scope` param is not actually sent with the request, so when
        // refreshing a token and the `email` scope does not exist, the `email`
        // claim does not get added to the token. this workaround allows us to
        // send in the "scope" and the auth0 "Enhance Claim" action will check
        // its existence
        //
        // @see https://community.auth0.com/t/original-granted-scopes-not-not-available-in-rules-action-when-refreshing-token/72419
        // @see https://github.com/auth0/auth0-spa-js/issues/896
        _scope: AUTH0_SCOPE,
      });
      operation.setContext({
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      });

      // return the headers to the context so httpLink can read them
      return forward(operation);
    });

    const httpLink = createUploadLink({
      fetch(uri, options) {
        // Pulling from headers to avoid any race conditions associated with
        // when the storage gets set.
        const { headers = {} } = options;
        const customerId = headers['X-SkySpecs-Customer'];
        return fetch(
          `${API_HOSTNAME}/graphql${customerId ? `?organizationId=${customerId}` : ''}`,
          options
        );
      },
    });

    const cache = new InMemoryCache({
      possibleTypes: {
        Task: ['RepairTask', 'InspectTask', 'OtherTask', 'InternalBladeInspectionTask'],
        DamageSchemaAttributeDefinition: [
          'DamageSchemaAttributeDefinitionString',
          'DamageSchemaAttributeDefinitionNumber',
          'DamageSchemaAttributeDefinitionDate',
        ],
        DamageSchemaConstraintCriteria: [
          'DamageSchemaConstraintCriteriaString',
          'DamageSchemaConstraintCriteriaNumber',
          'DamageSchemaConstraintCriteriaDate',
        ],
        DamageSchemaAttributeHistorical: [
          'DamageSchemaAttributeHistoricalNumber',
          'DamageSchemaAttributeHistoricalString',
          'DamageSchemaAttributeHistoricalDate',
        ],
      },
      typePolicies: {
        Image: {
          keyFields: image => `Image:${image.id ? image.id : image.url}`,
        },
        CD_Image: {
          keyFields: image => `Image:${image.id ? image.id : image.url}`,
        },
        GlobalWorkContainerField: {
          // disables normalization, embeds GlobalWorkContainerField within parent object in cache.
          keyFields: field => false,
        },
        Picture: {
          fields: {
            damages: {
              merge: false,
            },
          },
        },
        AnnotationObservation: {
          fields: {
            damages: {
              merge: false,
            },
          },
        },
        AnnotationGroup: {
          merge: false,
        },
        CD_Turbine: {
          keyFields: field => false,
        },
        CD_Location: {
          fields: {
            turbines: {
              merge: false,
            },
          },
        },
        // prevent apollo client from caching role objects by id since id's aren't unique yet
        Role: {
          keyFields: field => false,
        },
        CD_Role: {
          keyFields: field => false,
        },
        AssetFieldDefinition: {
          keyFields: field => false,
        },
      },
    });

    const defaultOptions = {
      errorPolicy: 'all',
    };

    const client = new ApolloClient({
      link: from([authLink, errorLink, retryLink, httpLink]),
      cache,
      name: APP_NAME,
    });

    client.defaultOptions = {
      query: defaultOptions,
      watchQuery: defaultOptions,
    };

    return client;
  };
  return <ApolloProvider {...props} client={client()} />;
};

export * from '@apollo/client';

export { graphql } from '@apollo/client/react/hoc';
