import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { LOGO_ICON, LOGO_FULL } from 'utils/constants';
import { FlexContainer } from 'utils/layouts/containers';

const { Sider } = Layout;

const Logo = styled(FlexContainer)`
  background: ${({ theme }) => theme.lightGray};
  text-align: center;
  overflow: hidden;
  height: 65px;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

const Image = styled.img`
  width: ${({ collapsed }) => (collapsed ? '64px' : '100%')};
  padding: 0.5em 1em;
`;

// !important for overriding antd
const StyledMenu = styled(Menu)`
  width: 100%;
  .ant-menu-item {
    font-size: 16px !important;
  }
`;

const StyledSider = styled(Sider)`
  border-right: 1px solid ${({ theme }) => theme.borderColor};
`;

export const SideNav = ({ tabs = [] }) => {
  const [collapsed, setCollapsed] = useState(undefined);
  const {
    location: { pathname },
  } = window;

  const activeTab = tabs
    .filter(({ link, subRoutes = [] }) => {
      const routes = [link, ...subRoutes];
      return !isEmpty(routes.filter(route => pathname.startsWith(route)));
    })
    .map(({ id }) => id);

  const handleCollapse = () => {
    setCollapsed(prevState => !prevState);
  };

  useEffect(() => {
    // let antd handle collapse for < lg breakpoint
    if (window.innerWidth < 992) return;

    // collapse sidebar when analytics is active
    setCollapsed(pathname.startsWith('/analytics'));
  }, [pathname, setCollapsed]);

  return (
    <StyledSider breakpoint="lg" collapsed={collapsed} onCollapse={handleCollapse}>
      <Logo>
        <Link to="/">
          <Image alt="Logo" collapsed={collapsed} src={collapsed ? LOGO_ICON : LOGO_FULL} />
        </Link>
      </Logo>
      <StyledMenu
        selectedKeys={activeTab}
        style={{
          padding: collapsed ? '1em 0' : '1em',
          borderRight: 'none',
        }}
      >
        {tabs.map(({ id, title, icon: Icon, link }) => {
          // Allow components to soft-reload themselves if navigating to the current path
          const isSelfLink = link === pathname;
          return (
            <Menu.Item id={id} key={id} title={title}>
              <Link replace={isSelfLink} to={{ pathname: link, state: { softReload: isSelfLink } }}>
                {Icon && <Icon />}
                {!collapsed && title}
              </Link>
            </Menu.Item>
          );
        })}
      </StyledMenu>
    </StyledSider>
  );
};
