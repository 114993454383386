const setHeapUser = ({ email, sub }) => {
  if (typeof window === 'undefined') {
    return null;
  }

  try {
    window.heap.identify(sub);
    window.heap.addUserProperties({ email });
  } catch (error) {
    console.error('heap failed to load');
  }
};

export { setHeapUser };
