// initialize bugsnag ASAP, before other imports
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React, { Fragment } from 'react';
//import LogRocket from 'logrocket';

let bugsnagClient = {};
// allow for render no-op
let BugSnag = Fragment;

/**
 * To enable units tests to operate normally, we try/catch JSDOM issues with bugsnag.
 * Related issues:
 *   - https://github.com/facebook/jest/issues/1909
 *   - https://github.com/bugsnag/bugsnag-js/issues/452
 */
try {
  const releaseStage = window.location.host.match(/dev\.|localhost/)
    ? 'development'
    : window.location.host.match('stage.')
    ? 'stage'
    : 'production';

  bugsnagClient = bugsnag({
    apiKey: '816ae7a226a56ff043670aa0042d9385',
    releaseStage,
    beforeSend(report) {
      if (window.location.host.match('localhost')) return false;
      //if (releaseStage === 'production') {
      //  report.updateMetaData('LogRocket', {
      //    sessionURL: LogRocket.sessionURL,
      //  });
      //}
    },
  });

  bugsnagClient.use(bugsnagReact, React);

  // wrap your entire app tree in the ErrorBoundary provided
  BugSnag = bugsnagClient.getPlugin('react');
} catch (error) {
  console.error(error.message);
}

const setBugsnagUser = ({ email, sub }) => {
  bugsnagClient.user = {
    email,
    id: sub,
  };
};

const notify = message => bugsnagClient.notify(message);

export { bugsnagClient, BugSnag, setBugsnagUser, notify };
