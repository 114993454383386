import './App.css';
import React, { Suspense, lazy, useEffect } from 'react';
import { Layout } from 'antd';
import {
  AndroidOutlined,
  FontColorsOutlined,
  ProfileOutlined,
  StarOutlined,
  SyncOutlined,
  LineHeightOutlined,
  SearchOutlined,
  RocketOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { Route, Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth0 } from '@auth0/auth0-react';
import { storage } from '../utils/localStorage';
import { setThirdPartyUser } from '../utils/auth';

import GlobalFooter from 'components/GlobalFooter';
import { SideNav } from 'components/SideNav';
import { Spinner } from 'components/Spinner';
import { uuidRegex } from 'utils/constants';
import { hasUserRole } from 'utils/auth';

const Annotations = lazy(() => import('./routes/Annotations'));
const AssetManager = lazy(() => import('./routes/AssetManager'));
const Damages = lazy(() => import('./routes/Damages'));
const Equipment = lazy(() => import('./routes/Equipment'));
const IngestionTable = lazy(() => import('./routes/Ingestion'));
const Inspection = lazy(() => import('./routes/Inspection'));
const LogFinder = lazy(() => import('./routes/LogFinder'));
const Modeling = lazy(() => import('./routes/Modeling'));
const ProjectDetails = lazy(() => import('./routes/ProjectDetails'));
const Projects = lazy(() => import('./routes/Projects'));

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  overflow-x: auto !important;
`;

export default function App() {
  const { user, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    const groups = user['https://skyspecs.com/jwt/ohi']?.groups;
    if (groups) {
      storage.setItem('ohi:groups', groups);
    }

    (async () => {
      try {
        const idToken = await getIdTokenClaims();
        setThirdPartyUser(idToken);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [user, getIdTokenClaims]);

  const createMenuItems = () =>
    [
      {
        title: 'Projects',
        icon: ProfileOutlined,
        link: '/projects',
        id: 'sidebar-projects-button',
      },
      {
        title: 'Asset Explorer',
        icon: FontColorsOutlined,
        link: '/asset-manager',
        id: 'sidebar-asset-manager-button',
      },
      hasUserRole('ops', 'analyst-admin') && {
        title: 'Ingestion',
        icon: SyncOutlined,
        link: '/ingestion',
        id: 'sidebar-ingestion-button',
      },
      hasUserRole('ops', 'analyst-admin') && {
        title: 'Log Finder',
        icon: SearchOutlined,
        link: '/logfinder',
        id: 'sidebar-logfinder-button',
      },
      hasUserRole('ops', 'auton') && {
        title: 'Modeling',
        icon: LineHeightOutlined,
        link: '/modeling',
        id: 'sidebar-modeling-button',
      },
      hasUserRole('ops', 'auton') && {
        title: 'Equipment',
        icon: RocketOutlined,
        link: '/equipment',
        id: 'sidebar-equipment-button',
      },
      hasUserRole('ops', 'qc2', 'analyst-admin') && {
        title: 'Annotations Triage',
        icon: PictureOutlined,
        link: '/annotations',
        id: 'annotations-button',
      },
    ].filter(Boolean);

  const menuItems = createMenuItems();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SideNav tabs={menuItems} />

      <StyledLayout>
        <Content>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route
                exact
                path="/"
                render={props => {
                  // handle old "hash" routes with a simple redirect
                  const { hash } = props.location;
                  return hash ? (
                    <Redirect to={hash.replace('#', '')} />
                  ) : (
                    <Redirect to="/projects" />
                  );
                }}
              />
              <Route exact path={'/projects'} component={Projects} />
              <Route
                exact
                path={`/projects/:projectId${uuidRegex}?`}
                render={props => {
                  return <Redirect to={`${props.location.pathname}/inspections`} />;
                }}
              />
              <Route path={`/projects/:projectId${uuidRegex}?`} component={ProjectDetails} />
              <Route
                path={`/inspections/:customerId${uuidRegex}/:id${uuidRegex}`}
                component={Inspection}
              />
              <Route path="/equipment" component={Equipment} key="equipment" />,
              <Route path="/ingestion" component={IngestionTable} key="ingestion" />,
              <Route path="/logfinder/:droneId?/:logId?" component={LogFinder} key="logfinder" />,
              <Route exact path="/modeling" component={Modeling} key="modeling" />,
              <Route exact path="/damages" component={Damages} key="damages" />,
              <Route path="/annotations" component={Annotations} key="annotations" />,
              <Route
                path={`/asset-manager/(customer)?/:customerId?/(location)?/:locationId?`}
                component={AssetManager}
                key="asset-manager"
              />
              <Redirect to="/projects" />
            </Switch>
          </Suspense>
        </Content>

        <GlobalFooter />
      </StyledLayout>
    </Layout>
  );
}
